import { Box } from '@deckee/deck-hand';
import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';

const StyledBox = styled(Box)`
  ${({ onClick }) => (onClick ? 'cursor: pointer' : undefined)};
`;

const IconCircleWrapper: FC<{
  iconSize?: number;
  bg?: string;
  color?: string;
  borderColor?: string;
  onClick?: () => void;
}> = ({
  borderColor,
  color,
  children,
  onClick,
  iconSize = 24,
  bg,
  ...rest
}) => {
  const theme = useTheme();
  const calcBorderColor = theme.colors[borderColor] || borderColor;
  const backgroundColor = bg || theme.colors.secondarylight;

  return (
    <StyledBox
      width={40}
      height={40}
      display="flex"
      flex="0 0 auto"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      bg={backgroundColor}
      onClick={onClick}
      border={borderColor ? `1px solid ${calcBorderColor}` : 'none'}
      color={color}
      {...rest}
    >
      <Box
        width={iconSize}
        height={iconSize}
        flex={`0 0 ${iconSize}px`}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
    </StyledBox>
  );
};

IconCircleWrapper.defaultProps = {
  iconSize: 24,
  bg: undefined,
};

export default IconCircleWrapper;
