import { DefaultTheme } from 'styled-components';
import { PrimaryTheme } from '@deckee/deck-hand';

const theme: DefaultTheme = {
  ...PrimaryTheme,
  colors: {
    ...PrimaryTheme.colors,
    orange: '#FF5C39',
    yellow: '#FFAB00',
    navy: '#001A70',
    latte: '#F2ECDF',
    white: '#FFFFFF',
    offWhite: '#F4F4F4',
    black: '#1a1a1a',
    grey: '#484848',
    midGrey: '#CBCBCB',
    red: '#A80000',
    green: '#167A4C',
  },
  typeface: {
    // primary: '"HelveticaNeueLTPro-Lt", Helvetica, Arial, sans-serif',
    // personality: '"HelveticaNeueLTPro-Lt", Helvetica, Arial Bold, sans-serif',
    primary: 'Helvetica Neue, Helvetica, Arial, Arimo, sans-serif',
    personality: 'Helvetica Neue, Helvetica, Arial Bold, Arimo, sans-serif',
  },
  maxWidth: '1300px',
  defaultMargin: '22px',
  defaultRadius: '5px',
};

export default theme;
