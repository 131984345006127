export function createLoginUrl(redirectTo?: string): string {
  if (redirectTo) {
    return `/api/auth/login?returnTo=${encodeURIComponent(redirectTo)}`;
  }
  return `/api/auth/login`;
}

export function createLogOutUrl(): string {
  return `/api/auth/logout`;
}

export function createProfileUrl(): string {
  return `/profile`;
}
