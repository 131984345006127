import { Trip } from '../interfaces/trip';
import { TripWaypoint } from '../interfaces/trip-waypoint';

export const buildWaypointsFromTrip = (
  trip: Trip,
): {
  allWaypoints: TripWaypoint[];
  startWaypoint: TripWaypoint;
  endWaypoint: TripWaypoint;
} => {
  const waypoints: TripWaypoint[] = [...(trip?.waypoints || [])];
  trip &&
    waypoints.push({
      id: 'START',
      name: trip?.startPointName || '',
      latLon: trip?.startPointLatLon,
      ordinal: 0,
    });

  trip &&
    waypoints.push({
      id: 'END',
      name: trip?.endPointName || '',
      latLon: trip?.endPointLatLon,
      ordinal: trip?.waypoints?.length + 1,
    });

  const allWaypoints = waypoints.sort((a, b) =>
    a.ordinal < b.ordinal ? -1 : 1,
  );

  const startWaypoint = allWaypoints[0];
  const endWaypoint = allWaypoints[allWaypoints.length - 1];
  return {
    allWaypoints,
    startWaypoint,
    endWaypoint,
  };
};
