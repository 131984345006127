/* eslint-disable camelcase */
import * as Yup from 'yup';
import { Watercraft } from './watercraft';

/** Type param `T` is a `number` through the REST API but a `string` through GraphQL. Defaults to `number`. */
export interface CustomerProfile<T = number> {
  id?: T;
  deckeeMemberId?: string;
  fullName: string;
  title: string | null;
  email?: string | null;
  phoneNumber: string;
  phoneLandlineNumber?: string | null;
  watercrafts?: Watercraft[];
  hidden?: boolean;
}

export const CustomerProfileFormSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  phoneLandlineNumber: Yup.string(),
});
