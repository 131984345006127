import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import * as Sentry from '@sentry/node';
import { UserProfile, UserProvider } from '@auth0/nextjs-auth0';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { withProfiler } from '@sentry/react';
import getConfig from 'next/config';
import { ThemeProvider } from 'styled-components';
import TagManager from 'react-gtm-module';
import Layout from '../components/layout';
import theme from '../components/theme';
import GlobalStyle from '../components/global-styles';
import debug from '../lib/debug-log';
import CreateTripProvider from '../providers/CreateTripProvider';
import { useRouter } from 'next/router';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          // eslint-disable-next-line no-param-reassign
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
      new Integrations.BrowserTracing(),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.GITHUB_SHA,
    environment: process.env.NAMESPACE,
    tracesSampleRate: 0.2, // can adjust to capture more traces.
  });
}

// custom layout can be added to the app through here.
const App = ({
  Component,
  pageProps,
  err,
}: AppProps & { err: Error }): React.ReactElement<AppProps> => {
  const { user } = pageProps as { user: UserProfile };

  useEffect(() => {
    // only add gtm in certain environments.
    debug('========> namespace', {
      namespace: process.env.NAMESPACE,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      nodeEnv: process.env.NODE_ENV,
      sentryRelease: process.env.GITHUB_SHA,
    });

    if (process.env.NAMESPACE === 'production') {
      // Configure GTM
      const tagManagerArgs = {
        gtmId: 'GTM-NZ2F8NS',
        dataLayer: {
          userId: user ? user.sub : undefined,
        },
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [user]);

  const router = useRouter();

  return (
    <UserProvider user={user}>
      <ThemeProvider theme={theme}>
        <Head>
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css"
            rel="stylesheet"
          />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Head>
        <GlobalStyle />

        <Layout>
          {/* Disabling props spreading here as we have no way of knowing the props ahead of time. */}
          {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}

          {/* GROSS */}
          {router.pathname?.indexOf('/trips/create') === 0 ||
          router.pathname?.indexOf('/customer-details') === 0 ? (
            <CreateTripProvider>
              <Component {...pageProps} err={err} />
            </CreateTripProvider>
          ) : (
            <Component {...pageProps} err={err} />
          )}
        </Layout>
      </ThemeProvider>
    </UserProvider>
  );
};

export default withProfiler(App);
