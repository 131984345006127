import { PermissionGroupDetails } from '../../interfaces/group-role';

export type UserPermissions =
  | 'contact-log:list'
  | 'groups:create'
  | 'groups:edit'
  | 'groups:list'
  | 'posts:edit'
  | 'reports:access'
  | 'reports:export'
  | 'reports:heatmap'
  | 'trips:create'
  | 'trips:edit'
  | 'trips:history'
  | 'trips:list'
  | 'trips:search'
  | 'users:create'
  | 'users:list'
  | 'waypoints:create'
  | 'waypoints:edit'
  | 'waypoints:list';

interface Rules {
  [permissionLevel: string]: {
    static: UserPermissions[];
    dynamic?: {
      [action in UserPermissions]?: (
        permissionGroupDetails: PermissionGroupDetails[],
        data?: Record<string, unknown>,
      ) => boolean;
    };
    displayName: string;
  };
}

const onlyAllowInGroups = (
  permissionGroupDetails,
  data?: { groups: string[] },
) => {
  const groups = data?.groups;
  if (!groups) {
    console.error('No group data was passed into onlyAllowInGroups!');
    return false;
  }
  return !!permissionGroupDetails.find((permissionGroupDetail) =>
    groups.includes(permissionGroupDetail.groupId),
  );
};

const groupMember: Rules['groupMember'] = {
  displayName: 'Group member',
  static: [
    'contact-log:list',
    'groups:list',
    'trips:create',
    'trips:list',
    'trips:history',

    // Not tested yet
    'trips:search',
    'waypoints:create',
    'waypoints:edit',
    'waypoints:list',
  ],
  dynamic: {
    'trips:edit': onlyAllowInGroups,
  },
};

const groupAdmin: Rules['groupAdmin'] = {
  displayName: 'Group admin',
  static: [
    ...groupMember.static,
    'users:create',
    'users:list',
    'reports:access',
  ],
  dynamic: {
    ...groupMember.dynamic,
    'groups:edit': onlyAllowInGroups,
  },
};

const regionMember: Rules['regionMember'] = {
  ...groupMember,
  displayName: 'Region member',
};

const regionAdmin: Rules['regionAdmin'] = {
  ...groupAdmin,
  displayName: 'Region admin',
};

const emergencyServiceMember: Rules['emergencyServiceMember'] = {
  displayName: 'Emergency service member',

  static: [
    'contact-log:list',
    'groups:list',
    'trips:create',
    'trips:edit',
    'trips:history',
    'trips:list',
    'trips:search',
  ],
};

const emergencyServiceAdmin: Rules['emergencyServiceAdmin'] = {
  ...emergencyServiceMember,
  displayName: 'Emergency service admin',

  static: [
    ...emergencyServiceMember.static,
    'users:create',
    'users:list',
    'trips:search',
    'reports:access',
  ],
};

const commsSupportMember: Rules['commsSupportMember'] = {
  displayName: 'Comms support member',
  static: [],
};

const commsSupportAdmin: Rules['commsSupportAdmin'] = {
  displayName: 'Comms support admin',
  static: ['contact-log:list', 'users:list', 'users:create', 'reports:access'],
};

const superAdmin: Rules['superAdmin'] = {
  displayName: 'Super admin',
  static: [
    'contact-log:list',
    'groups:create',
    'groups:edit',
    'groups:list',
    'posts:edit',
    'trips:create',
    'trips:edit',
    'trips:history',
    'trips:list',
    'trips:search',
    'users:create',
    'users:list',
    'waypoints:create',
    'waypoints:edit',
    'waypoints:list',
    'reports:access',
    'reports:heatmap',
    'reports:export',
  ],
};

// List of features only available to developers
export const developerFeatures = [];

const rules: Rules = {
  regionAdmin,
  regionMember,
  groupAdmin,
  groupMember,
  emergencyServiceAdmin,
  emergencyServiceMember,
  commsSupportMember,
  commsSupportAdmin,
  superAdmin,
};

export default rules;
