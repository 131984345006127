import { createContext } from 'react';
import { SectionValidation } from '../hooks/use-active-trip-form-section';
import { CustomerProfile } from '../interfaces/customer-profile';
import { Trip } from '../interfaces/trip';
import { TripWaypoint } from '../interfaces/trip-waypoint';
import { Watercraft } from '../interfaces/watercraft';

/**
 * OVERVIEW
 *
 * Provides a trip and relevent functionality to the application. The trip context is used to store a trip,
 * build the waypoints (waypoints + start/end) etc so that functionality doesn't have to be repeated,
 * injected or prop-drilled
 *
 * There are two providers that are currently in use
 * - CreateTripProvider
 * - TripProvider
 *
 * TripProvider
 * The TripProvider is used to load a trip from the API and provide it to the application. It currently does
 * not implement the additional update functionalty, however the update logic could be moved into the
 * provider rather than in the feature components
 *
 * CreateTripProvider
 * The CreateTripProvider is used to create a new trip. This trip is not loaded from the api, but it created
 * a shared amongst its consumers. The challenge was to allow for partial types, which is why some types
 * have been force. This provider also validates, which should be applied to the TripProvider as well
 */
const TripContext = createContext<{
  trip?: Partial<Trip>;
  notes?: string;
  allWaypoints?: TripWaypoint[];
  startWaypoint?: TripWaypoint;
  endWaypoint?: TripWaypoint;
  tripIsValid?: boolean;
  tripSectionValidation?: SectionValidation;
  updateCustomerProfile?: (profile: Partial<CustomerProfile>) => void;
  updateTripDetails?: (trip: Partial<Trip>) => void;
  updateWatercraftDetails?: (watercraft: Partial<Watercraft>) => void;
  updateWaypoints?: (waypoints: Partial<TripWaypoint>[]) => void;
  updateEmergencyContacts?: (contacts: Partial<Trip['contacts']>) => void;
  updateNotes?: (notes: string) => void;
  saveTrip?: (watercraft: Partial<Watercraft>) => void;
  refreshTrip?: () => Promise<Trip>;
  resetTrip?: () => Promise<any>;
} | null>(null);

export default TripContext;
