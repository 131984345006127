import React, { ComponentProps } from 'react';
import IconTemplate from './icon-template';

const NoAudio = (
  props: Omit<ComponentProps<typeof IconTemplate>, 'path' | 'viewBox'>,
): React.ReactElement => (
  <IconTemplate
    viewBox="0 0 50 50"
    path="M1 0C.75 0 .512.113.312.313c-.398.398-.398.976 0 1.375l48 48c.2.199.489.312.688.312.2 0 .488-.113.688-.313.398-.398.398-.976 0-1.374l-7.563-7.563c4.832-3.777 7.688-9.543 7.688-15.844 0-7.699-4.325-14.593-11.126-18.093-.5-.2-1.082-.125-1.28.375-.2.699.007 1.3.406 1.5 6.199 3.101 10 9.414 10 16.312 0 5.7-2.641 10.977-7.063 14.375l-3.188-3.188c3.297-2.69 5.344-6.773 5.344-11.187 0-5.3-2.894-10.188-7.593-12.688-.5-.3-1.106-.124-1.407.376-.3.5-.117 1.113.282 1.312 4 2.2 6.624 6.398 6.624 11 0 3.828-1.734 7.43-4.687 9.75l-3.844-3.844c2.114-1.191 3.532-3.398 3.532-5.906 0-3.102-2.126-5.793-5.126-6.594-.5-.101-1.085.188-1.187.688-.102.5.188 1.117.688 1.218a4.73 4.73 0 0 1 3.625 4.594c0 1.992-1.243 3.801-3.032 4.5l-3.968-3.968V6.313C26.813 4.011 25.3 3 24 3c-.898 0-1.793.387-2.594 1.188L13.5 12.125 1.687.312C1.488.113 1.25 0 1 0Zm23 5c.8 0 .813 1.012.813 1.313v17.125l-10-10 7.874-7.844C23.188 5.195 23.602 5 24 5Zm-13.188 9.688L9.595 16H2.813c-1.7 0-3 1.3-3 3v12c0 1.7 1.3 3 3 3h6.78l11.72 11.688C22.413 46.788 23.3 47 24 47c1.7 0 2.813-1.488 2.813-3.688V30.688l-2-2v14.625c0 1-.313 1.687-.813 1.687-.3 0-.79-.29-1.188-.688L10.095 31.688c-.102-.102-.281-.477-.281-.875V19.188c0-.399.18-.801.28-1l2.095-2.094Zm-8 3.312H8c-.102.398-.188.887-.188 1.188v11.5c0 .398.086.914.188 1.312H2.812c-.601 0-1-.398-1-1V19c0-.602.399-1 1-1Z"
    {...props}
  />
);

NoAudio.defaultProps = {
  color: '#001A70',
};

export default NoAudio;
