import React, { useEffect, useRef } from 'react';

const Player = ({ url }) => {
  const audio = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (audio?.current) {
      audio.current.play();
    }
  }, []);

  useEffect(
    () => () => {
      if (audio?.current) {
        audio.current.pause();
      }
    },
    [],
  );

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <audio src={url} autoPlay />;
};

export default Player;
