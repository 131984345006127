import React, { ComponentProps } from 'react';
import IconTemplate from './icon-template';

const Logout = (
  props: Omit<ComponentProps<typeof IconTemplate>, 'path' | 'viewBox'>,
): React.ReactElement => (
  <IconTemplate
    viewBox="0 0 50 50"
    path="M22.205 2a1 1 0 0 0-.986.838l-.973 5.955c-1.17.34-2.285.8-3.336 1.371l-4.914-3.51a1 1 0 0 0-1.287.106l-3.89 3.886a1 1 0 0 0-.112 1.282l3.457 4.945a16.92 16.92 0 0 0-1.398 3.36l-5.93.986a1 1 0 0 0-.834.986v5.5a1 1 0 0 0 .824.986l5.934 1.051a16.82 16.82 0 0 0 1.394 3.36l-3.5 4.896a1 1 0 0 0 .106 1.287l3.888 3.89a1 1 0 0 0 1.28.114l4.955-3.469a16.85 16.85 0 0 0 3.346 1.381l.99 5.963a1 1 0 0 0 .986.836h5.5a1 1 0 0 0 .986-.826l1.061-5.986a16.85 16.85 0 0 0 3.33-1.397l4.988 3.5a1 1 0 0 0 1.282-.111l3.888-3.893a1 1 0 0 0 .104-1.29l-3.557-4.938a16.769 16.769 0 0 0 1.367-3.311l6.018-1.055a1 1 0 0 0 .826-.986v-5.5a1 1 0 0 0-.838-.986l-6.008-.983a16.885 16.885 0 0 0-1.37-3.306l3.507-4.998a1 1 0 0 0-.111-1.282l-3.89-3.888a1 1 0 0 0-1.292-.104l-4.924 3.541a16.76 16.76 0 0 0-3.334-1.389l-1.047-5.984A1 1 0 0 0 27.705 2h-5.5zm.852 2h3.808l.996 5.686a1 1 0 0 0 .743.798c1.462.365 2.836.943 4.09 1.702a1 1 0 0 0 1.1-.043l4.68-3.364 2.694 2.694-3.332 4.748a1 1 0 0 0-.04 1.09 14.926 14.926 0 0 1 1.686 4.07 1 1 0 0 0 .809.744l5.707.934v3.808l-5.719 1.004a1 1 0 0 0-.797.746 14.798 14.798 0 0 1-1.681 4.069 1 1 0 0 0 .045 1.1l3.379 4.689-2.694 2.695-4.74-3.326a1 1 0 0 0-1.094-.035 14.894 14.894 0 0 1-4.08 1.709 1 1 0 0 0-.74.794L26.867 46h-3.814l-.942-5.662a1 1 0 0 0-.746-.807 14.902 14.902 0 0 1-4.105-1.695 1 1 0 0 0-1.088.039l-4.703 3.295-2.696-2.7 3.325-4.646a1 1 0 0 0 .04-1.1 14.859 14.859 0 0 1-1.71-4.115 1 1 0 0 0-.795-.742l-5.631-1v-3.814l5.627-.936a1 1 0 0 0 .807-.744 14.953 14.953 0 0 1 1.71-4.117 1 1 0 0 0-.035-1.092L8.826 11.47l2.697-2.696 4.663 3.332a1 1 0 0 0 1.095.043 14.83 14.83 0 0 1 4.104-1.685 1 1 0 0 0 .748-.81L23.057 4zM25 17c-4.406 0-8 3.594-8 8 0 4.406 3.594 8 8 8 4.406 0 8-3.594 8-8 0-4.406-3.594-8-8-8zm0 2c3.326 0 6 2.674 6 6s-2.674 6-6 6-6-2.674-6-6 2.674-6 6-6z"
    {...props}
  />
);

Logout.defaultProps = {
  color: '#001A70',
};

export default Logout;
