import React from 'react';

/** Template component for SVG icons. Just provide `path` and `viewBox`. */
const IconTemplate = ({
  path,
  viewBox,
  size,
  color = '#001A70',
  transform = 'none',
  onClick,
  cursor,
}: {
  path: string;
  viewBox: string;
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox={viewBox}
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path fill={color} d={path} />
  </svg>
);

IconTemplate.defaultProps = {
  color: '#001A70',
  transform: 'none',
  onClick: null,
  cursor: undefined,
};

export default IconTemplate;
