import * as Yup from 'yup';

export type TripContactType = 'onboard' | 'onland';
export interface ContactDetail {
  id?: string;
  name: string;
  email?: string;
  contactNumber: string;
  createdByMember?: string;
  deckeeId?: string;
}

export interface TripContact {
  id?: string;
  contactType?: TripContactType;
  contactDetails: ContactDetail;
  deckeeId?: string;
}

export const TripContactSchema = Yup.object().shape({
  contactType: Yup.string()
    .oneOf(['onboard', 'onland'])
    .required('Contact type is required'),
  contactDetails: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').nullable(),
    contactNumber: Yup.string().required('Contact number is required'),
  }),
});
