import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledImg = styled.img<{ src: string; style: unknown }>`
  cursor: pointer;
  width: auto;
`;

const Logo = forwardRef(
  (
    {
      height,
      marginTop,
    }: {
      height?: number;
      marginTop?: React.ReactText;
    },
    ref,
  ): React.ReactElement => (
    <StyledImg
      src="/DG_Logo_RGB.svg"
      style={{ height: `${height}px`, marginTop: `${marginTop}` }}
    />
  ),
);

Logo.defaultProps = {
  height: 40,
  marginTop: '25px',
};

export default Logo;
