import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  /**
  * @license
  * MyFonts Webfont Build ID 4002489, 2021-01-26T18:08:58-0500
  *
  * The fonts listed in this notice are subject to the End User License
  * Agreement(s) entered into by the website owner. All other parties are
  * explicitly restricted from using the Licensed Webfonts(s).
  *
  * You may obtain a valid license at the URLs below.
  *
  * Webfont: HelveticaNeueLTPro-Lt by Linotype
  * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-45-light-189172/
  *
  * Webfont: HelveticaNeueLTPro-HvEx by Linotype
  * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-83-heavy-extended/
  *
  *
  * Webfonts copyright: Copyright &amp;#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
  *
  * © 2021 MyFonts Inc
  */

 @font-face {
    font-family: 'HelveticaNeueLTPro-Lt';
    src: url('/assets/fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeueLTPro-Lt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    /* This declaration is style linked to the font above and should have the same family name */
    /* Basically we are using the medium (MD) version for our bold */
    font-family: 'HelveticaNeueLTPro-Lt';
    src: url('/assets/fonts/HelveticaNeueLTPro-Md.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeueLTPro-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'HelveticaNeueLTPro-HvEx';
    src: url('/assets/fonts/HelveticaNeueLTPro-HvEx.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeueLTPro-HvEx.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.offWhite};
    /* font-size: 1.6rem; */
    line-height: 1.4;
    font-family: ${({ theme }) => theme.typeface.primary};
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) => theme.typeface.personality};
    font-weight: normal;
    margin: 0.5rem 0 1rem;
    line-height: 1.2;
  }

  h1 {
    font-family: ${({ theme }) => theme.typeface.personality};
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    margin:0;
    /* identical to box height */

    letter-spacing: 0.0325px;
    color: ${({ theme }) => theme.colors.black};
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    /* line-height: 27px; */
    letter-spacing: 0.0325px;
  }

  a {
    color: #001a70;
    text-decoration: none;
    font-family: ${({ theme }) => theme.typeface.primary};
    font-style: normal;
    font-weight: 500;
    /* font-size: 18px; */
  }
`;

export default GlobalStyle;
