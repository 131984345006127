import React, { ComponentProps } from 'react';
import IconTemplate from './icon-template';

const Audio = (
  props: Omit<ComponentProps<typeof IconTemplate>, 'path' | 'viewBox'>,
): React.ReactElement => (
  <IconTemplate
    viewBox="0 0 50 50"
    path="M24.156 3c-.304 0-.605.063-.906.156a4.133 4.133 0 0 0-1.688 1.063l-11.75 11.75H3A3.04 3.04 0 0 0-.031 19v12A3.04 3.04 0 0 0 3 34.031h6.813L21.53 45.688c1.117 1.12 2.551 1.613 3.719 1.062 1.176-.555 1.75-1.89 1.75-3.438v-37c0-1.5-.766-2.746-1.938-3.156A2.78 2.78 0 0 0 24.157 3Zm.032 2a.78.78 0 0 1 .25.031c.285.098.562.352.562 1.282v37c0 1.066-.352 1.542-.594 1.656-.242.113-.73.125-1.5-.657L10.313 31.72a.466.466 0 0 0-.063-.032c.074.067-.25-.566-.25-.937v-11.5c0-.371.336-1.012.25-.938a.472.472 0 0 0 .063-.062L22.968 5.625c.468-.473.91-.621 1.218-.625ZM38.28 6.813a.999.999 0 0 0-.218 1.906c5.949 3 10 9.152 10 16.281s-4.051 13.281-10 16.281a1 1 0 1 0 .875 1.781C45.538 39.73 50.062 32.895 50.062 25c0-7.895-4.523-14.73-11.124-18.063a.979.979 0 0 0-.563-.125h-.094Zm-3.562 5.312a.995.995 0 0 0-.864.848 1 1 0 0 0 .583 1.058C38.34 16.137 41 20.246 41 25c0 4.738-2.648 8.86-6.531 10.969a.997.997 0 0 0-.39 1.36.997.997 0 0 0 1.358.39A14.466 14.466 0 0 0 43 25c0-5.5-3.063-10.305-7.594-12.75a1.012 1.012 0 0 0-.593-.125h-.094ZM3 18.031h5.188c-.13.383-.188.801-.188 1.219v11.5c0 .418.063.832.188 1.219H3A.964.964 0 0 1 2.031 31V19c0-.54.43-.969.969-.969Zm27.531.375a.996.996 0 0 0-.875.946.998.998 0 0 0 .782 1.023A4.782 4.782 0 0 1 34 25a4.782 4.782 0 0 1-3.563 4.625 1 1 0 0 0 .5 1.938C33.847 30.8 36 28.136 36 25c0-3.137-2.152-5.8-5.063-6.563a1.02 1.02 0 0 0-.406-.03Z"
    {...props}
  />
);

Audio.defaultProps = {
  color: '#001A70',
};

export default Audio;
