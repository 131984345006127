import React, { FC, useContext, useState } from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import Header from './header';
import { AppContext } from '../AppContext';

const Container = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  margin-top: 30px;
`;

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [appContext, setAppContext] = useState({
    enabledSound: false,
  });

  return (
    <>
      <Head>
        <title>Deckee - Search and Rescue Dashboard</title>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>

      <AppContext.Provider value={[appContext, setAppContext]}>
        <Header />

        <main>
          <Container>{children}</Container>
        </main>
      </AppContext.Provider>
    </>
  );
};

export default Layout;
