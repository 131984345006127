/* eslint-disable camelcase */
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { Base } from './base';
import { CustomerProfile } from './customer-profile';
import { Point } from './point';
import { TripContact } from './trip-contact';
import { FloatPlanNote } from './trip-note';
import { TripWaypoint } from './trip-waypoint';
import { Watercraft } from './watercraft';

export enum TripSearchFields {
  details = 'All details',
  proximityToSearch = 'Proximity to location',
  proximityToLatLon = 'Proximity to coordinates (decimal)',
}

export enum HighestStatusLevel {
  DueNow = 'DUE_NOW',
  Sar30 = 'SAR_30',
  Overdue = 'OVERDUE_15',
  None = 'NONE',
}

export interface Trip {
  isDiving?: boolean | null;
  deckeeId?: string;
  timeType?: 'return' | 'arrival';
  highestStatusLevel?: HighestStatusLevel;
  watercraft: Watercraft;
  id: number;
  startPointName?: string;
  startPointLatLon: {
    lat: number;
    lon: number;
  };
  waypoints?: TripWaypoint[];
  endPointName?: string;
  endPointLatLon: {
    lat: number;
    lon: number;
  };
  startTime: Date;
  startTimeTimezone?: string;
  startBase?: Base;
  endBase?: Base;
  endTime: string;
  endTimeTimezone?: string;
  endedTime?: Date;
  numberOnBoard: number;
  contacts?: TripContact[];
  lastKnownPosition?: {
    latitude: number;
    longitude: number;
    timestamp: Date;
  };
  emergency?: boolean;
  incidentNumber?: string | null;
  notes?: FloatPlanNote[];
  base?: {
    id: number;
    name: string;
    latLon: Point;
  };
  owner: CustomerProfile;

  // A dumping ground for contextual data from the api
  meta?: {
    [key: string | number]: any;
  };
}

export function getMinimumTripEndTime() {
  return `${DateTime.now().toFormat('yyyy-MM-dd')}T${DateTime.now().toFormat(
    'T',
  )}`;
}

// Made this a function so that the minimum end time isn't in the past by the time the user enters it
export const GetTripDetailsFormSchema = () =>
  Yup.object().shape({
    timeType: Yup.string()
      .oneOf(['return', 'arrival'])
      .required('Trip must specify if it is a return or arrival trip'),
    endTime: Yup.date()
      .required('End time is required')
      .min(getMinimumTripEndTime(), 'End time must be in the future'),
    numberOnBoard: Yup.number()
      .min(1, 'Must be at least one person on board')
      .required('Must specify the number of people on board'),
  });

// @deprecated
export const TripSchema = Yup.object().shape({
  vesselRegistration: Yup.string(),
  vesselName: Yup.string(),
  vesselMakeModel: Yup.string(),
  vesselCallSign: Yup.string(),
  startPointName: Yup.string(),
  startPointLatLon: Yup.object()
    .shape({
      lat: Yup.number()
        .moreThan(-90, 'Latitude Required')
        .lessThan(90, 'Latitude Required')
        .required('Required'),
      lon: Yup.number().required('Required'),
    })
    .required('Start Point is Required')
    .nullable(),
  endPointName: Yup.string(),
  endPointLatLon: Yup.object()
    .shape({
      lat: Yup.number()
        .moreThan(-90, 'Latitude Required')
        .lessThan(90, 'Latitude Required')
        .required('Required'),
      lon: Yup.number()
        .moreThan(-180, 'Longitude Required')
        .lessThan(180, 'Longitude Required')
        .required('Required'),
    })
    .required('End Point is Required')
    .nullable(),
  startTime: Yup.date().required('Required'),
  // the next two fields are temporary fields for input only.
  plannedReturnTimeTemp: Yup.string().required('Required'),
  plannedReturnDateTemp: Yup.string().required('Required'),
  plannedReturnTime: Yup.date(),
  numberOnBoard: Yup.number().min(1).required('Required'),
  radioType: Yup.string(),
  contacts: Yup.array()
    .of(
      Yup.object()
        .shape({
          name: Yup.string().required('Required'),
          contactNumber: Yup.string().required('Required'),
        })
        .required('Required'),
    )
    .min(1, 'At least one contact must be provided'),
});
