import { useState } from 'react';
import useSWR from 'swr';
import fetcher from '../lib/fetcher';

function useSoundAlarm(): {
  soundAlarm: boolean;
  isLoading: boolean;
  error: unknown;
} {
  const [data, setData] = useState<boolean>(false);

  const { error } = useSWR<boolean>(`/api/trips/alarm`, fetcher, {
    refreshInterval: 30000,
    onSuccess: (data) => {
      setData(data);

      // Clear data after 5 seconds, so that when we poll again, we can show the alarm again
      setTimeout(() => {
        setData(false);
      }, 5000);
    },
  });
  return {
    soundAlarm: data,
    isLoading: !error && !data,
    error,
  };
}

export default useSoundAlarm;
