import React, { ComponentProps } from 'react';
import IconTemplate from './icon-template';

const Chart = (
  props: Omit<ComponentProps<typeof IconTemplate>, 'path' | 'viewBox'>,
): React.ReactElement => (
  <IconTemplate
    viewBox="0 0 50 50"
    path="M45 2c-1.105 0-2 .895-2 2 0 .293.074.563.188.813l-8 11.187c-.063-.004-.126 0-.188 0-.398 0-.781.11-1.094.313L27 12.874A2.005 2.005 0 0 0 25 11c-1.105 0-2 .895-2 2 0 .105.016.21.031.313l-7.312 5.812A1.987 1.987 0 0 0 15 19c-1 0-1.82.73-1.969 1.688l-6.812 2.75A1.975 1.975 0 0 0 5 23a1.999 1.999 0 1 0 0 4 1.989 1.989 0 0 0 1.969-1.719l6.812-2.718c.34.265.758.437 1.219.437 1.105 0 2-.895 2-2 0-.105-.016-.21-.031-.313l7.312-5.812c.223.086.465.125.719.125.398 0 .781-.11 1.094-.313L33 18.125A2.005 2.005 0 0 0 35 20c1.105 0 2-.895 2-2 0-.293-.074-.563-.188-.813l8-11.187c.063.004.126 0 .188 0a1.999 1.999 0 1 0 0-4Zm-4 13v35h8V15Zm2 2h4v31h-4Zm-22 7v26h8V24Zm2 2h4v22h-4Zm8 3v21h8V29Zm2 2h4v17h-4Zm-22 1v18h8V32Zm2 2h4v14h-4ZM1 36v14h8V36Zm2 2h4v10H3Z"
    {...props}
  />
);

Chart.defaultProps = {
  color: '#001A70',
};

export default Chart;
