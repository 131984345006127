import { UserBlocked } from './errors';

const fetcher = async (
  input: RequestInfo,
  init?: RequestInit,
): Promise<any> => {
  const res = await fetch(input, init);

  if (res.ok) {
    return res.json();
  }

  const json = await res.json();

  if (json.errorCode === UserBlocked.errorCode) {
    window.location.href = '/api/auth/logout';
  }

  throw new Error(`API returned error code ${res.status}`);
};

export default fetcher;
