import React, { ComponentProps } from 'react';
import IconTemplate from './icon-template';

const Logout = (
  props: Omit<ComponentProps<typeof IconTemplate>, 'path' | 'viewBox'>,
): React.ReactElement => (
  <IconTemplate
    viewBox="0 0 50 50"
    path="M3 0C1.355 0 0 1.355 0 3v44c0 1.645 1.355 3 3 3h34c1.645 0 3-1.355 3-3v-8l-2 2v6c0 .563-.438 1-1 1H3c-.563 0-1-.438-1-1V3c0-.566.434-1 1-1h34c.563 0 1 .438 1 1v6l2 2V3c0-1.645-1.355-3-3-3Zm34.844 13.094a.994.994 0 0 0-.782.703c-.105.367.004.758.282 1.015L46.53 24H16.906c-.55.027-.98.496-.953 1.047.027.55.496.98 1.047.953h29.531l-9.187 9.188a1.004 1.004 0 0 0-.348 1.003c.086.371.379.664.75.75.375.086.762-.05 1.004-.347l10.906-10.875.688-.719-.688-.719L38.75 13.406a1.004 1.004 0 0 0-.813-.312h-.093Z"
    {...props}
  />
);

Logout.defaultProps = {
  color: '#001A70',
};

export default Logout;
